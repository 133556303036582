//import IMask from 'imask';
//import lightGallery from 'lightgallery';
//import lgFullscreen from 'lightgallery/plugins/fullscreen';
//import { Modal } from 'bootstrap/js/dist/modal';
//import Modal from 'bootstrap/js/dist/modal'

import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

// Инициализация при загрузке DOM-дерева
document.addEventListener('DOMContentLoaded', () => {
  //initTelMasks();
  //initLightGalleries();
  initFeedbacksSlider();
  //initTemplateSlider2();

  initCountDownTimer();
});

// Функция инициализации масок телефонных номеров
function initTelMasks() {
  const telInputs = document.querySelectorAll('input[type=tel]');
  telInputs.forEach((el) => {
    IMask(el, {
      mask: '+{7} 000 000 00 00',
    });
  });
}

// Функция инициализации галерей изображений
function initLightGalleries() {
  const galleryElements = document.querySelectorAll('[data-gallery]');
  galleryElements.forEach((el) => {
    lightGallery(el, {
      licenseKey: '0000 0000 0000 0000',
      download: false,
      fullScreen: false,
      plugins: [lgFullscreen],
      selector: '[data-src]',
    });
  });
}

function initFeedbacksSlider() {
  const sliderFeedbacks = new Swiper('.slider-feedbacks', {
    loop: true,
    watchSlidesProgress: true,
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 400,
    autoplay: {
      delay: 5000,
    },
    /* effect: 'fade',
    fadeEffect: {
      crossFade: true,
    }, */
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    /* pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }, */
  });
}
function initTemplateSlider2() {
  const slider = new Swiper('.slider-template2', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 400,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
}

/* 
 localStorage.setItem('sessionStartTime', Number(localStorage.getItem('sessionStartTime'))+129600000);
 // '1734061438874'
 */
function updateCountdownTime() {
  let sessionStartTime = localStorage.getItem('sessionStartTime');

  if (sessionStartTime) {
    sessionStartTime = Date.now();
    localStorage.setItem('sessionStartTime', sessionStartTime);
  }
}

function initCountDownTimer() {
  let link_1 = 'https://salebot.site/3558e2a86ab0b135cb6dbc6d3914749f_1';
  let link_2 = 'https://salebot.site/679118f42a94d7ed240f304e2b6e36f7_1';
  let link_3 = 'https://salebot.site/e0ddcec21e1ec2d60e5216c7c9096713_1';
  let sessionStartTime = localStorage.getItem('sessionStartTime');

  if (!sessionStartTime) {
    sessionStartTime = Date.now();
    localStorage.setItem('sessionStartTime', sessionStartTime);
  } else {
    // Преобразуем сохраненное время в число
    sessionStartTime = parseInt(sessionStartTime, 10);
  }

  // Устанавливаем время окончания первой акции (12 часов от начала сессии)
  let firstActionEndTime = sessionStartTime + 12 * 60 * 60 * 1000;

  // Антон говорит поменять на 72 часов
  firstActionEndTime = sessionStartTime + 72 * 60 * 60 * 1000;

  // Устанавливаем время окончания второй акции (48 часов от начала сессии)
  //let secondActionEndTime = sessionStartTime + 48 * 60 * 60 * 1000;
  let secondActionEndTime = sessionStartTime + 48 * 60 * 60 * 1000;

  function updateCountdown() {
    const now = Date.now();
    let distance = firstActionEndTime - now;
    let price = 1999;
    let message = '';

    /*  if (distance <= 0) {
      distance = secondActionEndTime - now;
      //price = 3499;
      price = 1999;

      if (distance <= 0) {
        //price = 5400;
        price = 1999;
        message = 'Успей купить!';
      }

      changePricePayment(price);
    } */

    // Расчет времени
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 72)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Обновление таймера
    document.getElementById('countdown-hours').innerHTML = `${pad(hours)}`;
    document.getElementById('countdown-minutes').innerHTML = `${pad(minutes)}`;
    document.getElementById('countdown-seconds').innerHTML = `${pad(seconds)}`;

    document.querySelectorAll('[data-price]').forEach((el) => {
      el.innerHTML = price;
    });

    /* console.log('sessionStartTime', sessionStartTime);
    console.log('firstActionEndTime', firstActionEndTime);
    console.log('distance', distance);
    console.log('hours', hours);
 */
    // document.getElementById('price').innerHTML = `Цена: ${price} руб.`;

    // Если время истекло, вывести сообщение
    if (distance <= 0 && price === 5400) {
      document.getElementById('countdown').innerHTML = 'Время акции истекло!';

      message = '';
    } else {
      // Обновление таймера через 1 секунду
      setTimeout(updateCountdown, 1000);
    }

    document.getElementById('message').innerHTML = message;
  }

  // Первоначальное обновление таймера
  updateCountdown();

  // Функция для сброса таймера
  /* function resetTimer() {
    sessionStartTime = Date.now();
    localStorage.setItem('sessionStartTime', sessionStartTime);
    firstActionEndTime = sessionStartTime + 12 * 60 * 60 * 1000;
    secondActionEndTime = sessionStartTime + 48 * 60 * 60 * 1000;
    updateCountdown();
  } */

  //window.resetTimer = resetTimer();
}

function changePricePayment(newPrice) {
  document.querySelectorAll('[data-prodamusprice]').forEach((el) => {
    el.dataset.prodamusprice = newPrice;
  });
}

function pad(number) {
  return number < 10 ? '0' + number : number;
}

// Экспорт модального окна для доступа извне
//window.modal = Modal;
